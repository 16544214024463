
<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="get_table_list"
            :slot_table_list="['operation','status']"
            :form_data_seo.sync="form_data_seo"
            :is_created_get_list="is_created_get_list"
            :submit_preprocessing="submit_preprocessing"
			ref="list"
        >
            <div slot="left_btn">
                <slot name="left_btn"></slot>
            </div>
            <div slot="right_btn">
                <slot name="right_btn"></slot>
            </div>

            <template slot="operation" slot-scope="data">
                <a @click="handle_details_click(data.record)">查看详情</a>
            </template>
            <template slot="status" slot-scope="data">
                <span
                    v-for="item in config.approval_status_list"
                    :key="item.key"
                    v-show="data.text == item.key"
                >{{item.value}}</span>
            </template>
        </TableList>
    </div>
</template>

<script>
import TableList from "@/components/TableList";

export default {
    name: "Index",
    props: {
        form_data_seo: {
            type: Object,
            default: () => {
                return {
                    show_submit_btn: true,
                    submit_name: "查询",
                    layout: "inline",
                    list: []
                }
            }
        },
        submit_preprocessing: {
            type: Object,
            default: () => {
                return {

                }
            }
        },
        is_created_get_list: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            default: () => {
                return [];
            }
        },
        get_table_list: {
            type: Function,
            default: () => {
                return null;
            }
        }
    },
    data() {
        return {
            seo_item_start_index: 2,
            config: this.$config,
        };
    },

    components: {
        TableList
    },
    created(query) {
        let form_data_seo = this.form_data_seo;
        let list = [
            {
                type: "tree-select",
                name: "department",
                title: "部门",
                options: {},
                treeData: [],
                multiple: true
            },
            {
                type: "text",
                name: "username",
                title: "用户",
                placeholder: '姓名/账号/工号/手机号',
                mode: "default",
                options: {},
                list: []
			},
			{
				type: "select",
				name: "user_status",
				title: "在职状态",
				mode: "default",
				options: {},
				list: this.$config.user_status_list
			},
            {
                type: "select",
                name: "status",
                title: "审批状态",
                options: {},
                list: [
                    ...this.$config.status_all,
                    ...this.$config.approval_status_list
                ],
            },
            {
                type: "range_date",
                name: "range_date",
                title: "创建时间",
                options: {},
                start: {
                    name: 'start_time'
                },
                end: {
                    name: 'end_time'
                },
            }
        ];

        form_data_seo.list.forEach((item, index) => {
            let condition = false;
            for (let i = 0; i < list.length; i++) {
                if (item.name == list[i].name) {
                    condition = true;
                }
            }
            if (!condition) {
                if (this.seo_item_start_index > -1) {
                    list.splice(this.seo_item_start_index, 0, form_data_seo.list[index])
                    this.seo_item_start_index++;
                } else {
                    list.push(form_data_seo.list[index])
                }
            }
        })
        form_data_seo.list = list;
        this.$emit('update:form_data_seo', form_data_seo)

        // 添加预处理参数
        let submit_preprocessing = JSON.parse(JSON.stringify(this.submit_preprocessing));
        if (submit_preprocessing.array_to_string) {
            submit_preprocessing.array_to_string.push('department')
        } else {
            submit_preprocessing.array_to_string = ['department'];
        }
        this.$emit('update:submit_preprocessing', submit_preprocessing)

        // 获取部门
        this.$method.get_department().then(res => {
            let form_data = JSON.parse(JSON.stringify(this.form_data_seo));
            form_data.list = form_data.list.map(item => {
                if (item.name == "department") {
                    item.treeData = res;
                }
                return {
                    ...item
                };
            });

            this.$emit('update:form_data_seo', form_data)
		});
		

		this.$nextTick(()=>{

			this.$refs.list.fixed_seo_data = {
				...this.$route.query,
			}
			this.$refs.list.get_list();
		})
    },
    methods: {
        handle_details_click(record) {
            this.$emit('handle_details_click', {
                record
            })
        }
    }
};
</script>

<style lang="less">
</style>