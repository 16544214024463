<template>
    <div class="approval_cc">
        <ul>
            <li v-for="item in list" :key="item.username">
                <a-avatar :size="40" :src="item.headimg"  />
                <div class="name">{{item.username}}</div>
                <div class="status">
					<span v-if="item.status == 1">已阅读</span>
					<span v-else-if="item.status == 0">未阅读</span>
				</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            default() {
                return [];
            }
        }
    }
}
</script>

<style lang="less" scoped>
.approval_cc {
    ul {
		display: flex; 
        li {
			display: flex; 
			flex-direction: column;
			align-items: center;
			padding: 15px 0;
			padding-right: 15px;
			.name{
				padding-top: 5px;
			}
            .status {
                
               
            }
        }
    }
}
</style>

