<template>
    <div>
        <UserHeader :head_img="details.headimg" :name="details.title||details.approval_type_name" :info="details.create_time" />
        <div class="status_img">
            <!-- <img :src="'../assets/img/approval_status_'+details.status+'.png'" alt /> -->
			<div class="approval_status_img" :class="['approval_status_'+details.status]">
				
			</div>
        </div>
    </div>
</template>

<script>
import UserHeader from './UserHeader'
export default {
	components:{
		UserHeader
	},
    props: {
        details: {
            type: Object,
            default() {
                return {};
            }
        },

    }
}
</script>

<style lang="less" scoped>
.approval_status_img{
	position: absolute; right: 15px; top: 15px;
}
</style>